import './index.css';
import { useState, lazy, Suspense } from 'react';

const LoginForm = lazy(() => import('./Login.js'));
const NavBar = lazy(() => import('./Nav.js'));
const Upload = lazy(() => import('./UploadDiv.js'));
const ExportTable = lazy(() => import('./ExportTable.js'));
const LabForm = lazy(() => import('./labForm.js'));
const IndexTable = lazy(() => import('./IndexTable.js'));

function App() {
  window.fullCopy = ""
  var [ loggedIn, setLoggedIn ] = useState(false);
  var [panel, setPanel] = useState('transcribe');

  if (!loggedIn) {;
  return (
    <>
    <link
  rel="stylesheet"
  href="https://cdn.jsdelivr.net/npm/@picocss/pico@2/css/pico.min.css"
/>
    <div className="App">
      <Suspense fallback={<div style={{ textAlign: "center", fontSize: "5em"}}>Alkalmazás betöltése...</div>}>
      <LoginForm setLoggedIn={setLoggedIn} />
      </Suspense>
    </div>
    </>
  );
} else {
  switch (panel) {
    case 'transcribe':
      return (
        <>
        <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/@picocss/pico@2/css/pico.min.css"
      />
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.2/css/all.min.css" integrity="sha512-SnH5WK+bZxgPHs44uWIX+LLJAJ9/2PkPKZ5QiAj6Ta86w+fsb2TkcmfRyVX3pBnMFcV7oQPJkl9QevSCWr3W6A==" crossorigin="anonymous" referrerpolicy="no-referrer" />
        <div className="App">
          <NavBar setPanel={setPanel} />
          <div style={{marginTop: '5vh', marginLeft: '5vw', marginRight: '5vw'}}>
          <p style={{marginTop: '0px' , textAlign: 'left', fontSize: "30px"}}>Keresés</p>
          <div className="overflow-auto">
    <Suspense fallback={<div style={{ textAlign: "center", fontSize: "20px"}}>Töltés...</div>}>
          <IndexTable setLoggedIn={setLoggedIn} />
          </Suspense>
          </div>
          </div>
        </div>
        </>
      );
    case 'upload':
      return (
        <>
        <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/@picocss/pico@2/css/pico.min.css"
      />
        <div className="App">
        <Suspense fallback={<div style={{ textAlign: "center", fontSize: "20px"}}>Töltés...</div>}>
          <NavBar setPanel={setPanel} />
          <Upload />
          </Suspense>
        </div>
        </>
      );
    case 'export':
      return (
        <>
        <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/@picocss/pico@2/css/pico.min.css"
      />
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.2/css/all.min.css" integrity="sha512-SnH5WK+bZxgPHs44uWIX+LLJAJ9/2PkPKZ5QiAj6Ta86w+fsb2TkcmfRyVX3pBnMFcV7oQPJkl9QevSCWr3W6A==" crossorigin="anonymous" referrerpolicy="no-referrer" />
        <div className="App">
        <NavBar setPanel={setPanel} />
        <div style={{marginTop: '5vh', marginLeft: '5vw', marginRight: '5vw'}}>
            <p style={{marginTop: '0px' , textAlign: 'left', fontSize: "30px"}}>Keresés</p>
            <div className="overflow-auto">
            <Suspense fallback={<div style={{ textAlign: "center", fontSize: "20px"}}>Töltés...</div>}>
          <ExportTable />
          </Suspense>
          </div>
          </div>
        </div>
        </>
      );
      case 'lab':
        return (
        <>
        <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/@picocss/pico@2/css/pico.min.css"
      />
        <div className="App">
        <NavBar setPanel={setPanel} />
        <div style={{marginTop: '5vh', marginLeft: '5vw', marginRight: '5vw'}}>
            <div className="overflow-auto">
            <Suspense fallback={<div style={{ textAlign: "center", fontSize: "20px"}}>Töltés...</div>}>
              <LabForm setLoggedIn={setLoggedIn} />
              </Suspense>
          </div>
          </div>
        </div>
        </>
        )
    default:
      return (
        <>
        <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/@picocss/pico@2/css/pico.min.css"
      />
        <div className="App">
        <NavBar setPanel={setPanel} />
        <div style={{marginTop: '5vh', marginLeft: '5vw', marginRight: '5vw'}}>
            <h1 style={{marginTop: '0px' , textAlign: 'left'}}>Keresés</h1>
            <div className="overflow-auto">
            <Suspense fallback={<div style={{ textAlign: "center", fontSize: "20px"}}>Töltés...</div>}>
          <IndexTable setLoggedIn={setLoggedIn} />
          </Suspense>
          </div>
          </div>
        </div>
        </>
      );
    }

}
}

export default App;
